import "./Main.css";
import "../CurrentProjects/CurrentProjects.css";
import React from "react";
import AboutUs from "./AboutUs";
import OurTeam from "./OurTeam.js";
import Partners from "./Partners";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Help from "./Help";
import CurrentProjects from "../CurrentProjects/CurrentProjects";
import Reports from "./Reports.js";
import { t } from "i18next";

export default function Main() {
  return (
     <div className="main">
      <div className="container statistic-wrap my-5">
        <Row className="statistic-block">
          <Col md className="stat-item ">
            <div className="number-stat">256</div>
            <div className="name-stat">{t("zvit num proj")}</div>
          </Col>
          <Col md className="stat-item">
            <div className="number-stat">1,215,155.24</div>
            <div className="name-stat">{t("zvit num money")}</div>
          </Col>
          <Col md className="stat-item">
            <div className="number-stat">2.215</div>
            <div className="name-stat">{t("zvit num donats")}</div>
          </Col>
        </Row>
      </div>
      <AboutUs />
      
      <OurTeam />
      <Help />
      <div className="container">
        <CurrentProjects />
      </div>

      {/* <Reports /> */}
      <Partners />
    </div>
  );
}
