/*import TeamImg from "../../assets/people-hiking.png";
import React from "react";
import { t } from "i18next";

export default function OurTeam() {
  return (
    <div className="our-Team container">
      <h3 className="section-title-text py-5">
        <div className="title-line"></div>
        {t("our team title")}
      </h3>
      <div className="our-Team-info">
        <img src={TeamImg} alt="our team" className="img-fluid"></img>
        <h3 className="block-title py-5 m-0">{t("team secod title")}</h3>
        <p className="">{t("team secod p")}</p>
      </div>
    </div>
  );
}
*/

import TeamImg from "../../assets/people-hiking.png";
import React from "react";
import "./Main.css";
import { t } from "i18next";
import Osipov from "../../assets/osipov.png";
import Titarenko from "../../assets/Titarenko.jpg";
import Vladislav from "../../assets/Vladislav.jpg";
import Ruslan from "../../assets/ruslan.jpg";
import Evgen from "../../assets/Evgen.jpg";
import Sergiy from "../../assets/sergiy.jpg";

export default function OurTeam() {
  return (
    <div className="our-Team container">
       <h3 className="section-title-text py-5">
         <div className="title-line"></div>
         {t("our team title")}
       </h3>
       {/* <div className="our-Team-info">
         <img src={TeamImg} alt="our team" className="img-fluid"></img>
         <h3 className="block-title py-5 m-0">{t("team secod title")}</h3>
         <p className="">{t("team secod p")}</p>
       </div> */}
      <ul class="team__list"> 
            <li class="team__item">
              <img class="team__photo" src={Vladislav} alt="teammate photo"></img>
            {/*  <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}
              <span class="team__teammate blok-title">
              {t("vladislav")}   </span>
              <span class="team__position">
                Засновник/голова           </span>
              {/* <a href="mailto:oresta.brit@gmail.com " class="team__email">
                oresta@bonukraine.com              </a> */}
            </li>
          </ul>
      <ul class="team__list">
            <li class="team__item">
             <img class="team__photo" src={Ruslan} alt="teammate photo"></img>
             {/* <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("ruslan")} </span>
              <span class="team__position">
                Керівник проєктів               </span>
              {/* <a href="mailto:bonusforua@gmail.com" class="team__email">
              bonusforua@gmail.com              </a> */}
            </li>
            <li class="team__item">
              <img class="team__photo" src={Evgen} alt="teammate photo"></img>
              {/* <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("evgen")} </span>
              <span class="team__position">
                Керівник проєктів              </span>
              {/* <a href="mailto:daiva@bonukraine.com" class="team__email">
                daiva@bonukraine.com              </a> */}
            </li>
          </ul>
          <ul class="team__list">
            <li class="team__item">
             <img class="team__photo" src={Titarenko} alt="teammate photo"></img>
              {/*  <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("titarenko")} </span>
              <span class="team__position">
              Керівник проєктів                </span>
            </li>
            <li class="team__item">
             
              {/*  <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}
              <img class="team__photo" src={Osipov} alt="slider prev" />
              <span class="team__teammate">
              {t("yaroslav")}  </span>
              <span class="team__position">
              Керівник проєктів        </span>
              {/* <a href="mailto:bonusforua@gmail.com" class="team__email">
              bonusforua@gmail.com              </a> */}
            </li>
            <li class="team__item">
             <img class="team__photo" src={Sergiy} alt="teammate photo"></img>
             {/* <img class="team__ukr-flag" src="https://bonukraine.com/wp-content/themes/bon/public/images/Flag_of_Ukraine.png" alt="team bon logo"></img>*/}

              <span class="team__teammate">
              {t("sergiy")} </span>
              <span class="team__position">
              Керівник проєктів                </span>
              {/* <a href="mailto:bonusforua@gmail.com" class="team__email">
              bonusforua@gmail.com              </a> */}
            </li>
          </ul>
          
    </div>
  );
}
