import ParImg1 from "../../assets/partner1.jpg";
import ParImg2 from "../../assets/partner2.png";
import ParImg3 from "../../assets/partner3.png";
import ParImg4 from "../../assets/partner4.jpg";
import ParImg5 from "../../assets/partner5.jpg";
import ParImg6 from "../../assets/partner6.jpg";
import ParImg7 from "../../assets/partner7.jpg";
import ParImg8 from "../../assets/partner8.png";
import ParImg9 from "../../assets/partner9.jpg";
import ParImg11 from "../../assets/partner11.jpg";
import ParImg10 from "../../assets/partner10.jpg";
import ParImg12 from "../../assets/partner12.png";
import ParImg13 from "../../assets/partner13.jpg";
import ParImg14 from "../../assets/partner14.jpg";
import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Slider.css";
import { t } from "i18next";
import { baseUrl } from "../../config";

export default function Slider() {

  return (
    <div className="slider-partners">
      <Carousel
        className="slider"
        prevIcon={
          <span aria-hidden="true" className="carousel-control-prev-icon">
            <img src={baseUrl + "/sliderPrev.png"} alt="slider prev" />
          </span>
        }
        nextIcon={
          <span aria-hidden="true" className="carousel-control-prev-icon">
            <img src={baseUrl + "/slideNext.png"} alt="slider next" />
          </span>
        }
      >
      <Carousel.Item>
      <div className="slider-screen container">
        <div className="card-patner">
          <img src={ParImg1} alt="partner" className="" />
        </div>
        <div className="card-patner">
          <img src={ParImg2} alt="partner" className="" />
        </div>
        <div className="card-patner">           
          <img src={ParImg3} alt="partner" className="" />
        </div>
        <div className="card-patner">
          <img src={ParImg4} alt="partner" className="" />
        </div>
      </div>
      </Carousel.Item>
      
      <Carousel.Item>
      <div className="slider-screen container">
        <div className="card-patner">
          <img src={ParImg5} alt="partner" className="" />
        </div>
        <div className="card-patner">
          <img src={ParImg6} alt="partner" className="" />
        </div>
        <div className="card-patner">
          <img src={ParImg7} alt="partner" className="" />
        </div>
        <div className="card-patner">
          <img src={ParImg8} alt="partner" className="" />
        </div>
      </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slider-screen container">
          <div md={6} className="card-patner">
            <img src={ParImg13} alt="partner" className="" />
          </div>
          <div md={6} className="card-patner">
            <img src={ParImg11} alt="partner" className="" />
          </div>
          <div md={6} className="card-patner">
            <img src={ParImg10} alt="partner" className="" />
          </div>
          <div md={6} className="card-patner">
            <img src={ParImg12} alt="partner" className="" />
          </div>
        </div>
        </Carousel.Item>
        </Carousel>
    </div>
   
   
  );

}
